@import "mixins"
.headerSport
    composes: headerSport from "components/pages/home/tournament/home-tournament-item.module.sass"
    margin-right: 0

    :global(.svg-icon)
        margin-right: 6px
        width: 20px
        height: 20px
        border-radius: 100%
        background: var(--home_headerSportIconBg)
        border: 1px solid var(--home_headerSportIconBorder)
        box-shadow: var(--sidebar_sportIconShadow)
        padding: 1px
        @include flex
        justify-content: center

        :global(.slots-icon-bg), :global(.live-casino-icon-bg)
            fill: rgb(var(--thirdaryColor))

.casinoRowGames
    composes: homeGames from "components/pages/home/tournament/home-tournament-item.module.sass"
    height: 204px

    :global
        .slick-track .slick-slide:first-of-type .casino-game-item
            margin-left: 0
